import React, { useEffect, useState } from 'react';

const DynamicBackground = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="fixed inset-0 overflow-hidden pointer-events-none">
      <div
        className="absolute w-64 h-64 bg-[#49A8FF] rounded-full opacity-20 blur-3xl transition-all duration-1000 ease-out"
        style={{
          top: `${Math.min(25 + (scrollY * 0.02), 90)}%`,
          left: `${25 + Math.sin(scrollY * 0.002) * 15}%`,
          transform: `translate(-50%, -50%) rotate(${scrollY * 0.1}deg)`,
        }}
      ></div>
      <div
        className="absolute w-96 h-96 bg-[#FF7CC3] rounded-full opacity-20 blur-3xl transition-all duration-1000 ease-out"
        style={{
          bottom: `${75 - (scrollY * 0.02)}%`,
          right: `${25 + Math.cos(scrollY * 0.002) * 15}%`,
          transform: `translate(50%, 50%) rotate(${-scrollY * 0.08}deg)`,
        }}
      ></div>
      <div
        className="absolute w-48 h-48 bg-[#FFB64F] rounded-full opacity-20 blur-3xl transition-all duration-1000 ease-out"
        style={{
          top: `${Math.min(75 + (scrollY * 0.02), 90)}%`,
          left: `${50 + Math.sin(scrollY * 0.003) * 20}%`,
          transform: `translate(-50%, -50%) rotate(${scrollY * 0.15}deg)`,
        }}
      ></div>
    </div>
  );
};

export default DynamicBackground;