"use client";

import React from 'react';
import Navbar from '../components/Navbar';
import Features from '../components/Features';
import HowItWorks from '../components/HowItWorks';
import Pricing from '../components/Pricing';
import FAQ from '../components/FAQ';
import Hero from '../components/Hero';
import DynamicBackground from '../components/DynamicBackground';
import SocialProof from '../components/SocialProof';
import Footer from '../components/Footer';
import CallToAction from '../components/CallToAction';
import { CornerRightDown } from 'lucide-react';

const Home: React.FC = () => {
  return (
    <div className='relative bg-[#0B0B0C] min-h-screen'>
      <DynamicBackground />
      <div className="relative z-10">
        <Navbar />
        
        {/* Hero section without container constraints */}
        <div id="hero" className="relative mb-12 pt-10">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="relative">
              <Hero />
            </div>
          </div>
          
          {/* Absolutely positioned dashboard preview */}
          <div className="absolute top-0 -right-1/3 w-3/4 h-full pointer-events-none mt-12 hidden sm:block opacity-70">
            <img
              src="https://storage.googleapis.com/saved_media_assets/dashboard-preview.png"
              alt="Dashboard Preview"
              className=""
              style={{ maxHeight: '560px'}} // This ensures the image maintains its size
            />
          </div>
        </div>

        {/* Rest of the content with container */}
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 mt-8">
          <main className="flex flex-col justify-between items-stretch">
            <div id="social-proof">
              <SocialProof />
            </div>
            <div id="how-it-works" className="py-20">
              <HowItWorks />
            </div>
            <div id="features" className="pt-10">
              <Features />
            </div>
            <div className="py-10">
              <CallToAction />
            </div>
          </main>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Home;