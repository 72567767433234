'use client';

import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from '@clerk/nextjs';
import { createDefaultPreset, checkUserPresets } from '../lib/api/services/preset';
import { PresetResponse } from '../lib/api/types';

interface PresetContextType {
  preset: PresetResponse | null;
  isInitialized: boolean;
}

const PresetContext = createContext<PresetContextType>({
  preset: null,
  isInitialized: false,
});

export const PresetProvider = ({ children }: { children: React.ReactNode }) => {
  const [preset, setPreset] = useState<PresetResponse | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const { isSignedIn, isLoaded } = useAuth();

  useEffect(() => {
    const initializePreset = async () => {
      if (!isLoaded || !isSignedIn) return;

      try {
        const hasPresets = await checkUserPresets();
        if (!hasPresets) {
          const newPreset = await createDefaultPreset();
          setPreset(newPreset);
        }
        setIsInitialized(true);
      } catch (error) {
        console.error('Error initializing preset:', error);
      }
    };

    initializePreset();
  }, [isSignedIn, isLoaded]);

  return (
    <PresetContext.Provider value={{ preset, isInitialized }}>
      {children}
    </PresetContext.Provider>
  );
};

export const usePreset = () => useContext(PresetContext); 