import React, { useState, useEffect } from 'react';

const PARTNERS = [
  {
    name: 'BoomTv',
    landscapeVideo: 'https://storage.googleapis.com/saved_media_assets/lol.mp4',
    verticalVideo: 'https://storage.googleapis.com/saved_media_assets/boom-vertical'
  },
  {
    name: 'Terps Esports',
    landscapeVideo: 'https://storage.googleapis.com/saved_media_assets/landscape-social-proof',
    verticalVideo: 'https://storage.googleapis.com/saved_media_assets/vertical-social-proof'
  },
  {
    name: 'Krunker.io',
    landscapeVideo: 'https://storage.googleapis.com/saved_media_assets/krunker-landscape',
    verticalVideo: 'https://storage.googleapis.com/saved_media_assets/krunker-vertical'
  },
  {
    name: 'Proving Grounds',
    landscapeVideo: 'https://storage.googleapis.com/saved_media_assets/proving-grounds-landscape',
    verticalVideo: 'https://storage.googleapis.com/saved_media_assets/proving-grounds-vertical'
  }
];

const logos = [
  {
    url: 'https://storage.googleapis.com/saved_media_assets/BoomtvLandingGray.png',
    alt: 'Boom TV'
  },
  {
    url: 'https://storage.googleapis.com/saved_media_assets/MarylandLandingGray.png',
    alt: 'University of Maryland'
  },
  {
    url: 'https://storage.googleapis.com/saved_media_assets/KrunkerLandingGray.png',
    alt: 'Krunker.io'
  },
  {
    url: 'https://storage.googleapis.com/saved_media_assets/ProvinggroundsLandingGray.png',
    alt: 'Proving Grounds'
  }
];

const SocialProof = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [videosFading, setVideosFading] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsTransitioning(true);
      setVideosFading(true);
      
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % PARTNERS.length);
        setVideosFading(false);
      }, 500);

      setTimeout(() => {
        setIsTransitioning(false);
      }, 500);
    }, 9000);
    
    return () => clearInterval(interval);
  }, []);

  return (
    <section className="w-full px-4 py-6 sm:py-8 md:py-12">
      <div className="relative w-full bg-[#0B0B0C] rounded-xl overflow-hidden p-4 sm:p-6 md:p-8 border border-gray-800">
        <div className="relative z-10 space-y-6 sm:space-y-8">
          {/* Title with currentWord */}
          <div className="flex flex-wrap justify-center items-center text-xl sm:text-2xl md:text-3xl font-bold text-white text-center">
            <span>Why</span>
            <div className="mx-2 sm:mx-3 relative inline-block overflow-hidden transition-all duration-500 text-[#ffce80]">
              <div 
                className={`whitespace-nowrap transition-opacity duration-500 text-[#ffce80] ${
                  isTransitioning ? 'opacity-0' : 'opacity-100'
                }`}
              >
                {PARTNERS[currentIndex].name}
              </div>
              <div className="invisible fixed">
                {PARTNERS.map((partner, i) => (
                  <span key={i} className="whitespace-nowrap">{partner.name}</span>
                ))}
              </div>
            </div>
            <span>loves Saved</span>
          </div>

          {/* Videos Grid */}
          <div className="grid grid-cols-1 md:grid-cols-8 gap-4 sm:gap-6">
            {/* Landscape Video */}
            <div className="md:col-span-6 relative rounded-xl overflow-hidden bg-gray-900">
              <div className="aspect-video">
                <video
                  key={PARTNERS[currentIndex].landscapeVideo}
                  className={`w-full h-full object-cover transition-opacity duration-500 ${
                    videosFading ? 'opacity-0' : 'opacity-100'
                  }`}
                  autoPlay
                  loop
                  muted
                  playsInline
                  src={PARTNERS[currentIndex].landscapeVideo}
                />
              </div>
              <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent" />
            </div>

            {/* Vertical Video */}
            <div className="md:col-span-2 relative rounded-xl overflow-hidden bg-gray-900">
              <div className="aspect-[9/16] max-h-96 md:max-h-full mx-auto">
                <video
                  key={PARTNERS[currentIndex].verticalVideo}
                  className={`w-full h-full object-cover transition-opacity duration-500 ${
                    videosFading ? 'opacity-0' : 'opacity-100'
                  }`}
                  autoPlay
                  loop
                  muted
                  playsInline
                  src={PARTNERS[currentIndex].verticalVideo}
                />
              </div>
              <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent" />
            </div>
          </div>

          {/* Logos */}
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-6 items-center justify-items-center">
            {logos.map((logo, index) => (
              <div 
                key={index} 
                className="w-full max-w-[150px] transition-all duration-300"
              >
                <div className="relative overflow-hidden">
                  <img
                    src={logo.url}
                    alt={logo.alt}
                    className={`
                      w-full h-8 sm:h-10 md:h-12 object-contain 
                      transition-all duration-500
                      filter grayscale
                      ${currentIndex === index && !isTransitioning ? 
                        'opacity-100 grayscale-0' : 
                        'opacity-50 grayscale'
                      }
                    `}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SocialProof;