import React, { useEffect, useRef, useState } from 'react';
import { Sparkles, Settings, Mail, Radio } from 'lucide-react';
import AnimatedCounter from './AnimatedCounter';
import FluidButton from './FluidButton';

const useScrollAnimation = (): [React.RefObject<HTMLDivElement>, boolean] => {
  const ref = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      {
        threshold: 0.1,
        rootMargin: '50px'
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return [ref, isVisible];
};

const GradientIcon = ({ children }) => (
  <div className="relative">
    {React.cloneElement(children, {
      className: "gradient-icon",
      style: {
        stroke: 'url(#gradient)',
      }
    })}
    <svg width="0" height="0">
      <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop stopColor="#FF84B1" offset="0%" />
        <stop stopColor="#FFCE80" offset="100%" />
      </linearGradient>
    </svg>
  </div>
);

const FeatureCard = ({ children, className, isHighlight = false, delay = 0 }) => {
  const [ref, isVisible] = useScrollAnimation();

  return (
    <div
      ref={ref}
      className={`
        relative group bg-[#0B0B0C] rounded-xl p-8 border border-gray-800 
        transition-all duration-700 overflow-hidden 
        ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-20'}
        ${isHighlight ? 'border-blue-500/50 animate-pulse-border before:absolute before:inset-0 before:rounded-xl before:border before:border-blue-500/50 before:animate-pulse-border' : ''} 
        ${className}
      `}
      style={{ transitionDelay: `${delay}ms` }}
    >
      <style jsx>{`
        @keyframes pulseBorder {
          0% { border-color: rgba(59, 130, 246, 0.2); }
          50% { border-color: rgba(59, 130, 246, 0.5); }
          100% { border-color: rgba(59, 130, 246, 0.2); }
        }
        .animate-pulse-border {
          animation: pulseBorder 2s ease-in-out infinite;
        }
      `}</style>
      
      <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
        <div className={`absolute inset-0 bg-gradient-to-r ${isHighlight ? 'from-blue-500/20 via-purple-500/20 to-pink-500/20' : 'from-pink-500/20 to-orange-500/20'} blur-xl`} />
        <div className="absolute inset-px bg-[#0B0B0C] rounded-xl" /> 
      </div>
      
      <div className={`absolute -inset-[100%] bg-gradient-to-r ${isHighlight ? 'from-blue-500/10 via-purple-500/10 to-pink-500/10' : 'from-pink-500/10 to-orange-500/10'} blur-3xl opacity-0 group-hover:opacity-100 transition-opacity duration-700 animate-slow-spin`} />
      
      <div className="relative">{children}</div>
    </div>
  );
};

const Features = () => {
  const [statsRef, statsVisible] = useScrollAnimation();

  return (
    <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold text-white text-center mb-8">Features</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-6 auto-rows-auto">
        <FeatureCard className="lg:col-span-4" delay={0}>
          <div className="flex justify-between items-start h-full">
            <div className="space-y-4">
              <div className="inline-flex items-center gap-2 px-3 py-1 rounded-full bg-gray-800/50 text-sm text-gray-300 font-bold hover:bg-gray-800/70 transition-colors">
                <GradientIcon><Sparkles size={18} /></GradientIcon>
                AUTO-HIGHLIGHT DETECTION
              </div>
              <p className="text-gray-300 max-w-md">
                Generate highlights from your streams- the best moments ranked by virality with time-stamps and subtitling.
              </p>
            </div>
            <div className="relative w-40 h-40" />
          </div>
        </FeatureCard>

        <FeatureCard className="lg:col-span-2" delay={100}>
          <div className="space-y-4">
            <div className="inline-flex items-center gap-2 px-3 py-1 rounded-full bg-gray-800/50 text-sm text-gray-300 font-bold hover:bg-gray-800/70 transition-colors">
              <GradientIcon><Settings size={18} /></GradientIcon>
              CUSTOMIZABILITY
            </div>
            <p className="text-gray-300">
              Control the subtitle font, color, and censorship. Update any subtitles in the editor. 
            </p>
          </div>
        </FeatureCard>

        <FeatureCard className="lg:col-span-2" delay={200}>
          <div className="space-y-4">
            <div className="inline-flex items-center gap-2 px-3 py-1 rounded-full bg-gray-800/50 text-sm text-gray-300 font-bold hover:bg-gray-800/70 transition-colors">
              <GradientIcon><Mail size={18} /></GradientIcon>
              EMAIL AUTOMATION
            </div>
            <p className="text-gray-300">
              Track a Twitch creator. Get emailed highlights after every stream.
            </p>
          </div>
        </FeatureCard>

        <FeatureCard className="lg:col-span-4" isHighlight delay={300}>
          <div className="flex justify-between items-start h-full">
            <div className="space-y-4">
              <div className="inline-flex items-center gap-2 px-3 py-1 rounded-full bg-blue-500/20 text-sm text-blue-200 font-bold hover:bg-blue-500/30 transition-colors">
                <GradientIcon><Radio size={18} /></GradientIcon>
                LIVE HIGHLIGHT DETECTION
              </div>
              <p className="text-gray-300 max-w-md">
                A Twitch bot in your chat that detects and creates highlights as they happen. 
              </p>
              <div className="pt-2">
                <FluidButton 
                  onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSdEKIVBMlqB0bR66krJlhy5t3bzCBJ-CR6jRfnEqA5K0VflbA/viewform?usp=dialog', '_blank')}
                >
                  Join the Waitlist
                </FluidButton>
              </div>
            </div>
            <div className="relative w-40 h-40" />
          </div>
        </FeatureCard>

        <div 
          ref={statsRef}
          className={`
            lg:col-span-6 bg-[#141416] rounded-xl p-8
            transition-all duration-700
            ${statsVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-20'}
          `}
          style={{ transitionDelay: '400ms' }}
        >
          <div className="flex justify-center items-center space-x-24">
            <AnimatedCounter end={8739} label="highlights generated" />
            <AnimatedCounter end={4000} label="stream hours processed" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;