// app/lib/api/services/automation.ts

import { BASE_URL, createHeaders } from '../utils';
import type { AutomationResponse } from '../types';

export async function addAutomation(twitchUsername: string) {
  const headers = await createHeaders();
  const response = await fetch(`${BASE_URL}/add-automation/`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ twitch_username: twitchUsername })
  });

  if (!response.ok) {
    if (response.status === 422) {
      const errorData = await response.json();
      throw new Error(`Validation error: ${JSON.stringify(errorData.detail)}`);
    }
    throw new Error(`Request failed with status ${response.status}`);
  }

  return response.json();
}

export async function getAutomations(): Promise<AutomationResponse> {
  const headers = await createHeaders();
  const response = await fetch(`${BASE_URL}/automations/`, {
    method: 'GET',
    headers
  });

  if (!response.ok) {
    if (response.status === 422) {
      const errorData = await response.json();
      throw new Error(`Validation error: ${JSON.stringify(errorData.detail)}`);
    }
    throw new Error(`Request failed with status ${response.status}`);
  }

  return response.json();
}

export async function deleteAutomation(twitchUsername: string) {
  const headers = await createHeaders();
  const response = await fetch(`${BASE_URL}/automation/${encodeURIComponent(twitchUsername)}`, {
    method: 'DELETE',
    headers
  });

  if (!response.ok) {
    if (response.status === 422) {
      const errorData = await response.json();
      throw new Error(`Validation error: ${JSON.stringify(errorData.detail)}`);
    }
    throw new Error(`Request failed with status ${response.status}`);
  }

  return response.json();
}
