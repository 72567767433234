// app/lib/api/services/interaction.ts

import { BASE_URL, createHeaders } from '../utils';
import type { LikedUpdate, CopyLinkUpdate, DownloadUpdate } from '../types';

export async function updateLikeStatus(request: LikedUpdate) {
  const headers = await createHeaders();
  const response = await fetch(`${BASE_URL}/update-like-status`, {
    method: 'POST',
    headers,
    body: JSON.stringify(request),
  });

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  return response.json();
}

export async function updateCopyLinkStatus(request: CopyLinkUpdate) {
  const headers = await createHeaders();
  const response = await fetch(`${BASE_URL}/update-copy-link-status`, {
    method: 'POST',
    headers,
    body: JSON.stringify(request),
  });

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  return response.json();
}

export async function updateDownloadStatus(request: DownloadUpdate) {
  const headers = await createHeaders();
  const response = await fetch(`${BASE_URL}/update-download-status`, {
    method: 'POST',
    headers,
    body: JSON.stringify(request),
  });

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  return response.json();
}