// app/lib/api/services/highlight.ts

import { BASE_URL, createHeaders, log } from '../utils';
import type { 
  HighlightRequest, 
  HighlightCard,
  CroppingTemplate
} from '../types';

export async function processClip(requestBody: HighlightRequest) {
  const headers = await createHeaders();
  log('Processing clip', requestBody);

  const response = await fetch(`${BASE_URL}/process-clip/`, {
    method: 'POST',
    headers,
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  const data = await response.json();
  log('Clip processing initiated', { jobId: data });
  return data;
}

export async function findHighlightsSer(url: string, numOfHighlights: number) {
  const headers = await createHeaders();
  const requestData = { url, num_of_highlights: numOfHighlights };

  const response = await fetch(`${BASE_URL}/find_highlights_ser`, {
    method: 'POST',
    headers,
    body: JSON.stringify(requestData),
  });

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  const data = await response.json();
  return data;
}

export async function getHighlights(streamId: string): Promise<string[]> {
  const headers = await createHeaders();
  const response = await fetch(`${BASE_URL}/get-highlights/${streamId}`, {
    method: 'GET',
    headers,
  });

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  return response.json();
}

export async function getRecentClips(cursor?: string, limit: number = 8) {
  if (limit < 1 || limit > 10) {
    throw new Error('Limit must be between 1 and 10');
  }

  const headers = await createHeaders();
  const url = new URL(`${BASE_URL}/get-recent-clips`);
  if (cursor) url.searchParams.append('cursor', cursor);
  url.searchParams.append('limit', limit.toString());

  const response = await fetch(url.toString(), {
    method: 'GET',
    headers,
  });

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  return response.json();
}

export const findAndCreateHighlights = async(requestBody: HighlightRequest) => {
  console.log('Request body for findAndCreateHighlights:', requestBody);
  const headers = await createHeaders();
  
  try {
    const response = await fetch(`${BASE_URL}/find_and_create_highlights`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        url: requestBody.url,
        HighlightConfig: {
          template: requestBody.HighlightConfig.template,
          text_color: requestBody.HighlightConfig.text_color,
          censor_subtitles: requestBody.HighlightConfig.censor_subtitles,
          facecam_coordinates: requestBody.HighlightConfig.facecam_coordinates,
          gameplay_bounds: requestBody.HighlightConfig.gameplay_bounds,
          font_name: requestBody.HighlightConfig.font_name,
          fullscreen_height_offset: requestBody.HighlightConfig.fullscreen_height_offset,
          nonfullscreen_height_offset: requestBody.HighlightConfig.nonfullscreen_height_offset,
          outline_thickness: requestBody.HighlightConfig.outline_thickness,
          outline_color: requestBody.HighlightConfig.outline_color
        }
      })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        `HTTP error! status: ${response.status}, message: ${JSON.stringify(errorData)}`
      );
    }

    const data = await response.json();
    log('Highlights created successfully', data);
    return data;
    
  } catch (error) {
    log('Error in findAndCreateHighlights', error);
    throw error;
  }
};

export async function getHighlightsWithUrls(streamId: string) {
  const headers = await createHeaders();
  const response = await fetch(`${BASE_URL}/get-highlights-with-urls/${streamId}`, {
    method: 'GET',
    headers,
  });

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  return response.json();
}

export async function getLandscapeHighlight(highlightId: string) {
  const headers = await createHeaders();
  const response = await fetch(`${BASE_URL}/get-landscape-highlight/${highlightId}`, {
    method: 'GET',
    headers,
  });

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  return response.json();
}

export async function getInfoFromHighlightId(highlightId: string) {
  const headers = await createHeaders();
  const response = await fetch(`${BASE_URL}/highlight-info/${highlightId}`, {
    method: 'GET',
    headers,
  });

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  return response.json();
}

export async function createClipFromHighlightId(highlightId: string) {
  const headers = await createHeaders();
  const response = await fetch(`${BASE_URL}/create-clip-from-highlight_id`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ highlight_id: highlightId }),
  });

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  return response.json();
}

export const findClipsFromStreamId = async (requestBody: {
  stream_id: string;
  subtitles: string | null;
  template: CroppingTemplate;
  text_color: string;
  censor_subtitles: boolean;
  facecam_coordinates: [number, number, number, number] | [] | null;
  gameplay_bounds: [number, number, number, number] | [] | null; 
  font_name: string | null;
}) => {
  const headers = await createHeaders();

  // Check if gameplay_bounds is an empty array, if so, set it to null
  if (Array.isArray(requestBody.gameplay_bounds) && requestBody.gameplay_bounds.length === 0) {
    requestBody.gameplay_bounds = null;
  }
  if (Array.isArray(requestBody.facecam_coordinates) && requestBody.facecam_coordinates.length === 0) {
    requestBody.facecam_coordinates = null;
  }

  const response = await fetch(`${BASE_URL}/find_clips_from_stream_id`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return await response.json();
};

export async function getAllHighlights(): Promise<HighlightCard[]> {
  const headers = await createHeaders();
  const response = await fetch(`${BASE_URL}/get-all-highlights/`, {
    method: 'GET',
    headers,
  });

  if (!response.ok) {
    throw new Error(`Failed to fetch highlights: ${response.statusText}`);
  }

  return response.json();
}

export async function updateHighlightFromHighlightId(highlightData: {
  highlight_id: string;
  stream_id: string;
  start_time: string;
  end_time: string;
  subtitles: string;
  template: CroppingTemplate;
  text_color: string;
  censor_subtitles: boolean;
  facecam_coordinates: number[];
  gameplay_bounds: number[];
  url: string;
  font_name: string | null;
}) {
  const headers = await createHeaders();
  log('Updating highlight', highlightData);

  const response = await fetch(`${BASE_URL}/update-highlights-from-highlight_id`, {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(highlightData),
  });

  if (!response.ok) {
    const error = `Request failed with status ${response.status}`;
    log('Update highlight error', error);
    throw new Error(error);
  }

  const data = await response.json();
  log('Highlight updated', data);
  return data;
}