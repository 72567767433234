import { useEffect, useState } from 'react';
import { useAuth } from '@clerk/nextjs';
import { createDefaultPreset, checkUserPresets } from '../lib/api/services/preset';

export function usePresetInitialization() {
  const { isSignedIn, isLoaded } = useAuth();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const initializePreset = async () => {
      if (!isLoaded || !isSignedIn) return;

      try {
        const hasPresets = await checkUserPresets();
        if (!hasPresets) {
          await createDefaultPreset();
        }
        setIsInitialized(true);
      } catch (error) {
        console.error('Error initializing preset:', error);
      }
    };

    initializePreset();
  }, [isSignedIn, isLoaded]);

  return isInitialized;
}