import React from 'react';

const HowItWorks = () => {
  return (
    <div className="container mx-auto px-4">
      <div className="relative w-full bg-[#0B0B0C] rounded-xl overflow-hidden p-8 border border-gray-800">
        {/* Gradient Circle */}
        <div 
          className="absolute top-0 right-0 w-[500px] h-[500px] -mr-48 -mt-48"
          style={{
            background: 'radial-gradient(circle at center, rgba(29, 108, 255, 0.4) 0%, rgba(29, 108, 255, 0) 70%)'
          }}
        />
        
        {/* Content Container */}
        <div className="relative grid grid-cols-1 lg:grid-cols-12 gap-8 items-center">
          {/* Left side - Title */}
          <div className="lg:col-span-4">
            <h2 className="text-3xl font-bold text-white">How It Works</h2>
          </div>
          
          {/* Right side - Description */}
          <div className="lg:col-span-8">
            <p className="text-gray-300 text-lg">
              Saved automatically generates viral short-form clips from your Twitch, YouTube, and Kick streams using AI and fine-tuned machine learning algorithms.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;