// app/lib/api/types/index.ts

export enum CroppingTemplate {
  FULLSCREEN = "fullscreen",
  CROPPED = "cropped",
  STACKED = "stacked"
}

export interface HighlightConfig {
    template: CroppingTemplate;
    text_color: string;
    censor_subtitles: boolean;
    facecam_coordinates: [number | null, number | null, number | null, number | null];
    gameplay_bounds: [number | null, number | null, number | null, number | null];
    font_name: string;
    fullscreen_height_offset: number | null;
    nonfullscreen_height_offset: number | null;
    outline_thickness: number | null;
    outline_color: string | null;
  }
  
  export interface HighlightCard {
    highlight_id: string| null;
    highlight_url: string;
    highlight_start_time: string | null;
    highlight_end_time: string | null;
    highlight_title: string;
    highlight_label: string;
    highlight_description: string;
    highlight_score: number | null;
    liked: boolean | null;
  }
  
  export interface StreamInfo {
    stream_id: string;
    stream_url: string;
    highlights: string[];
    created_at: string;
    thumbnail_url: string;
    stream_name: string;
    num_highlights: number;
  }
  
  export interface LivestreamInfo {
    livestream_id: string;
    created_at: string;
    thumbnail_url?: string;  // Optional fields use '?' in TypeScript
    livestream_title?: string;
  }
  
  export interface GetRecentLivestreamsResponse {
    items: LivestreamInfo[];
    next_cursor?: string;
  }
  
  export interface HighlightRequest {
    url: string;
    HighlightConfig: HighlightConfig;
  }
  
  export interface LikedUpdate {
    highlight_id: string;
    liked: boolean;
  }
  
  export interface CopyLinkUpdate {
    highlight_id: string;
  }
  
  export interface DownloadUpdate {
    highlight_id: string;
  }
  
  export interface AutomationResponse {
    twitch_usernames: string[];
    count: number;
  }
  
  export interface RecentClipsRequest {
    items: HighlightCard[];
    next_cursor: string;
  }
  
  export interface PresetBase {
    template: CroppingTemplate;
    font: string;
    text_color: string;
    censor_subtitles: boolean;
  }
  
  export interface PresetCreate extends PresetBase {
    //no preset_id needed for creation
  }
  
  export interface PresetUpdate extends Partial<PresetBase> {
    preset_id: string;
  }
  
  export interface PresetResponse extends PresetBase {
    preset_id: string;
  }
  
  export interface BotStatusResponse {
    message: string;
    activated: boolean;
  }
  
  export interface SubscriptionInfo {
    is_paying: boolean;
  }