// app/lib/api/utils.ts

export const BASE_URL = 'https://saved.bobbygeorge.dev';


declare global {
  interface Window {
    Clerk: any;
  }
}

export function log(message: string, data?: any) {
  console.log(`[API] ${message}`, data ? data : '');
}

export async function createHeaders() {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Accept', 'application/json');
  
  // Get the Clerk token
  const clerkToken = await window.Clerk.session?.getToken({
    template: "supabase",
  });
  headers.append('Authorization', `Bearer ${clerkToken}`);
  return headers;
}