import { useSession } from '@clerk/nextjs';
import { PresetCreate, PresetUpdate } from '../types';
import { BASE_URL, createHeaders } from '../utils';

export async function getTwitchUrl() {
    const headers = await createHeaders();
    const response = await fetch(`${BASE_URL}/twitch_url`, {
      method: 'GET',
      headers,
    });
  
    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }
  
    return response.json();
  }

export async function getRecentLivestreams(cursor?: string, limit: number = 8) {
  if (limit < 1 || limit > 10) {
    throw new Error('Limit must be between 1 and 10');
  }

  const headers = await createHeaders();
  const url = new URL(`${BASE_URL}/get-recent-livestreams`);
  if (cursor) url.searchParams.append('cursor', cursor);
  url.searchParams.append('limit', limit.toString());

  const response = await fetch(url.toString(), {
    method: 'GET',
    headers,
  });

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  return response.json();
}

export async function getLiveHighlightsWithUrls(livestreamId: string) {
  const headers = await createHeaders();
  const response = await fetch(`${BASE_URL}/get-live-highlights-with-urls/${livestreamId}`, {
    method: 'GET',
    headers,
  });

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  return response.json();
}

export async function getLivestreamInfo(livestreamId: string) {
  const headers = await createHeaders();
  const response = await fetch(`${BASE_URL}/livestream-info/${livestreamId}`, {
    method: 'GET',
    headers,
  });

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  return response.json();
}

export async function getBotStatus() {
    const headers = await createHeaders();
    const response = await fetch(`${BASE_URL}/bot-status`, {
      method: 'GET',
      headers,
    });
  
    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }
  
    return response.json();
}

export async function updateBotStatus(enabled: boolean) {    
    const headers = await createHeaders();
    const response = await fetch(`${BASE_URL}/update-bot-status?enabled=${enabled}`, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(enabled),
      }
    );
  
    if (!response.ok) {
      console.error('Failed to update bot status:', {
        status: response.status,
        statusText: response.statusText,
      });
      throw new Error(`Request failed with status ${response.status}`);
    }
  
    return response.json();
}

export async function getChatMessagesStatus() {
  const headers = await createHeaders();
  const response = await fetch(`${BASE_URL}/enable-chat-messages`, {
    method: 'GET',
    headers,
  });

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  return response.json();
}

export async function updateChatMessagesStatus(enabled: boolean) {
  const headers = await createHeaders();

  const response = await fetch(`${BASE_URL}/update-enable-chat-messages?enable_chat_messages=${enabled}`, {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(enabled),
  });

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  return response.json();
}