import React from 'react';
import Link from 'next/link';
import { SignedIn, SignedOut } from '@clerk/nextjs';
import { usePathname } from 'next/navigation';

const CallToAction = () => {
  const pathname = usePathname();

  return (
    <div className="container mx-auto px-4 relative">
      <div className="bg-white w-full py-8 px-4 sm:px-6 lg:px-8 rounded-xl relative overflow-hidden">
        {/* Gradient Background Effects */}
        <div className="absolute inset-0">
          <div 
            className="absolute inset-0 bg-gradient-to-r from-pink-500/10 via-purple-500/10 to-blue-500/10 blur-xl"
            style={{ willChange: 'transform' }}
          />
        </div>
        
        {/* Animated gradient orbs */}
        <div 
          className="absolute -inset-full bg-gradient-to-r from-pink-500/20 to-blue-500/20 blur-3xl opacity-30"
          style={{ 
            animation: 'spin 10s linear infinite',
            willChange: 'transform'
          }}
        />
        
        {/* Content container with z-index to ensure it's above gradients */}
        <div className="relative z-10">
          {/* Top text */}
          <div className="text-center mb-4">
            <p className="text-sm font-bold text-[#0B0B0C]">
              Start using Saved. No credit card or download required.
            </p>
          </div>

          {/* Main CTA Button */}
          <div className="flex justify-center mb-8">
            <SignedOut>
              <Link href="/sign-in" className="w-full max-w-md">
                <button 
                  className="w-full py-4 px-8 text-lg font-semibold rounded-xl bg-[#0B0B0C] hover:bg-[#2D2D2E] text-white transition-colors duration-200"
                >
                  Get highlights for free
                </button>
              </Link>
            </SignedOut>
            <SignedIn>
              <Link href="/streams" className="w-full max-w-md">
                <button 
                  className="w-full py-4 px-8 text-lg font-semibold rounded-xl bg-[#0B0B0C] hover:bg-[#2D2D2E] text-white transition-colors duration-200"
                >
                  Get highlights for free
                </button>
              </Link>
            </SignedIn>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallToAction;