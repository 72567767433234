// app/lib/api/services/subscription.ts

import { BASE_URL, createHeaders } from '../utils';

export async function createCheckoutSession(): Promise<string> {
  const headers = await createHeaders();
  const response = await fetch(`${BASE_URL}/create-checkout-session`, {
    method: 'POST',
    headers,
  });

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  const data = await response.json();
  return data.sessionId;
}

export async function createCancellationSession() {
  const headers = await createHeaders();
  console.log('Creating cancellation session');

  try {
    const response = await fetch(`${BASE_URL}/create-cancellation-session`, {
      method: 'POST',
      headers: headers,
    });

    console.log('Response status:', response.status);

    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    const data = await response.json();
    console.log('Received data:', data);

    if (typeof data === 'string' && data.startsWith('http')) {
      return data;
    } else if (data && data.url) {
      return data.url;
    } else {
      throw new Error('Invalid response format');
    }
  } catch (error) {
    console.error('Error in createCancellationSession:', error);
    throw error;
  }
}

export async function getUserSubscriptionInfo() {
  const headers = await createHeaders();
  const response = await fetch(`${BASE_URL}/get-user-subscription-info`, {
    method: 'GET',
    headers,
  });

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  return response.json();
}