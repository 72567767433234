// app/lib/api/services/frame.ts

import { BASE_URL, createHeaders } from '../utils';

// Helper function for checking job status
export async function checkStatus(jobId: string) {
  const headers = await createHeaders();
  const response = await fetch(`${BASE_URL}/check-status/${jobId}`, {
    method: 'GET',
    headers,
  });

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  return response.json();
}

export async function processFrame(url: string, video_type: string) {
  const headers = await createHeaders();
  const response = await fetch(`${BASE_URL}/process-frame/`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      clip_link: url,
      video_type: video_type
    }),
  });

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  const data = await response.json();
  const jobId = data.job_id;

  let jobData = null;
  while (jobData == null || jobData.status == 'processing') {
    const statusResponse = await fetch(`${BASE_URL}/check-status/${jobId}`, {
      headers
    });
    jobData = await statusResponse.json();

    if (!jobData.result?.frame) {
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  }

  return jobData;
}

export async function frameFromVod(clip_link: string, start_time: string) {
  const headers = await createHeaders();
  const response = await fetch(`${BASE_URL}/frame-from-vod/`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      clip_link,
      start_time
    }),
  });

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  const data = await response.json();
  
  // Poll for job completion
  let jobData = null;
  while (!jobData || jobData.status === 'processing') {
    jobData = await checkStatus(data.job_id);
    if (jobData.status !== 'completed') {
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  }

  return jobData;
}