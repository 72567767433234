import { BASE_URL, createHeaders } from '../utils';
import { CroppingTemplate, PresetCreate, PresetResponse, PresetUpdate } from '../types';

export async function createDefaultPreset(): Promise<PresetResponse> {
  const headers = await createHeaders();
  
  const defaultPreset: PresetCreate = {
    template: CroppingTemplate.FULLSCREEN,
    font: "CheGuevaraBarry-Brown",
    text_color: "yellow",
    censor_subtitles: true
  };

  const response = await fetch(`${BASE_URL}/presets`, {
    method: 'POST',
    headers,
    body: JSON.stringify(defaultPreset),
  });

  if (!response.ok) {
    throw new Error(`Failed to create preset: ${response.status}`);
  }

  return response.json();
}

export async function checkUserPresets(): Promise<boolean> {
  const headers = await createHeaders();
  
  const response = await fetch(`${BASE_URL}/presets`, {
    method: 'GET',
    headers,
  });

  if (!response.ok) {
    throw new Error(`Failed to check presets: ${response.status}`);
  }

  const presets = await response.json();
  return presets.length > 0;
}

export async function getPresets() {
  const headers = await createHeaders();
  const response = await fetch(`${BASE_URL}/presets`, {
    method: 'GET',
    headers,
  });

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  return response.json();
}

export async function updatePreset(presetData: PresetUpdate) {

  const headers = await createHeaders();
  const response = await fetch(`${BASE_URL}/presets`, {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(presetData),
  });

  if (!response.ok) {
    console.error('Failed to update preset:', {
      status: response.status,
      statusText: response.statusText,
      data: await response.text(),
    });
    throw new Error(`Request failed with status ${response.status}`);
  }

  return response.json();
}

export async function deletePreset(presetId: string) {
  const headers = await createHeaders();
  const response = await fetch(`${BASE_URL}/presets/${presetId}`, {
    method: 'DELETE',
    headers,
  });

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  return response.json();
}