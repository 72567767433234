import React from 'react';
import Link from 'next/link';
import Image from 'next/image';

const Footer = () => {
  return (
    <footer className="w-full py-4 bg-[#0B0B0C] mt-auto">
      <div className=" container mx-auto px-12">
        <div className="flex flex-col md:flex-row md:justify-between items-center gap-4 text-sm">
          {/* Copyright Text */}
          <div className="text-gray-300 font-bold text-center md:text-left">
            © Kudzu Media Inc, Saved.gg
          </div>
          
          {/* Navigation Links */}
          <nav className="flex flex-col md:flex-row items-center gap-4 md:gap-8 font-bold">
            <a
              href="https://calendly.com/aayushgupta-startupshell/30min"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-300 hover:text-white transition-colors duration-200"
            >
              Contact Us
            </a>
            <Link
              href="/terms"
              className="text-gray-300 hover:text-white transition-colors duration-200"
            >
              Terms of Service
            </Link>
            <Link
              href="/privacy"
              className="text-gray-300 hover:text-white transition-colors duration-200"
            >
              Privacy
            </Link>
          </nav>
        </div>
      </div>
    </footer>
  );
};

export default Footer;