import React, { useState } from 'react';
import { SignedIn, SignedOut, useAuth } from '@clerk/nextjs';
import Link from 'next/link';
import { getTwitchUrl } from '../lib/api';
import { usePresetInitialization } from '../hooks/usePresetInitialization';

const Hero = () => {
  const [showForm, setShowForm] = useState(false);
  const { isSignedIn } = useAuth();
  usePresetInitialization();

  const handleTwitchAuth = async () => {
    if (!isSignedIn) {
      window.location.href = '/sign-in?redirect=/twitch-bot';
      return;
    }

    try {
      const response = await getTwitchUrl();
      if (response.auth_url) {
        window.open(response.auth_url, '_blank', 'noopener,noreferrer');
      }
    } catch (error) {
      console.error('Error getting Twitch auth URL:', error);
    }
  };

  return (
    <div className="relative mt-4">
      {/* Content */}
      <div className="flex flex-col max-w-xl px-4">
        {/* Leading relaxed gives more spacing between lines */}
        <h1 className="text-5xl md:text-6xl lg:text-8xl font-bold mb-10 leading-tight">
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#93a5ff] via-[#ff82be] to-[#ffbc85]">
          {/* <span className="bg-clip-text bg-gradient-to-r text-[#ffffff]"> */}
            Real-time
            <br />
            highlights
            <br />
            for your
            <br />
            stream
          </span>
        </h1>

        <p className="text-gray-400 text-xl mb-8">
          Saved is a Twitch bot that automates
          <br />
          live highlight detection and clip editing.
        </p>

        <div className="relative">
          <div className="flex gap-4 items-center mb-4">
            <button
              onClick={handleTwitchAuth}
              className="px-6 py-3 bg-[#ffffff] hover:bg-[#d8d8d8] text-black rounded-lg transition-all duration-200 text-sm font-bold flex items-center"
            >
              <svg 
                viewBox="0 0 24 24" 
                className="w-4 h-4 mr-2 fill-current"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11.571 4.714h1.715v5.143H11.57zm4.715 0H18v5.143h-1.714zM6 0L1.714 4.286v15.428h5.143V24l4.286-4.286h3.428L22.286 12V0zm14.571 11.143l-3.428 3.428h-3.429l-3 3v-3H6.857V1.714h13.714Z"/>
              </svg>
              Add to Chat
            </button>
            
            <SignedIn>
              <Link href="/streams">
                <button
                  className="relative px-6 py-3 bg-white/10 text-white hover:bg-white/20 rounded-lg transition-all duration-200 text-sm font-bold group"
                >
                  Have a VOD? Try Now
                </button>
              </Link>
            </SignedIn>

            <SignedOut>
              <Link href="/sign-in">
                <button className="relative px-6 py-3 bg-white/10 text-white hover:bg-white/20 rounded-lg transition-all duration-200 text-sm font-bold group">
                  Try Now
                </button>
              </Link>
            </SignedOut>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;