// app/lib/api/services/stream.ts

import { BASE_URL, createHeaders } from '../utils';
import type { StreamInfo } from '../types';

export async function getStreams(cursor: string | null, limit = 8) {
  const headers = await createHeaders();
  const url = new URL(`${BASE_URL}/get-recent-streams`);
  
  if (cursor) url.searchParams.append('cursor', cursor);
  url.searchParams.append('limit', limit.toString());

  const response = await fetch(url.toString(), {
    method: 'GET',
    headers,
  });

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  return response.json();
}

export async function getInfoFromStreamId(streamId: string): Promise<StreamInfo> {
  const headers = await createHeaders();
  const response = await fetch(`${BASE_URL}/stream-info/${streamId}`, {
    method: 'GET',
    headers,
  });

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  return response.json();
}